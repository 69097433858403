/* Global CSS */

.bg {
  background-color: #1b1726;
}

.form-select {
  border-radius: 40px !important;
  margin: 0 20px;
  width: 25%;
  padding: 10px 30px;
}

.form-control {
  width: 50%;
  border-radius: 30px;
  padding: 10px 30px;
}

.companyCard {
  cursor: pointer !important;
  padding: 20px;
  width: 290px;
  height: 200px;
  border: 1px solid #999;
  margin: 15px;
  margin-bottom: 40px;
  border-radius: 4px;
}

.blog-list .blog-item {
  margin: 50px 0;
}

.blogText {
  padding-left: 20px;
}

.blogImage {
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
  background-color: #f5ecec;
}

.blogSmall {
  width: 100%;
  height: 200px;
  background-color: #f5ecec;
  border-radius: 4px;
  object-fit: cover;
}

.page {
  max-width: 900px;
  margin: 0 auto;
  padding: 10px 0;
}

.not-found {
  width: 90%;
  height: 300px;
  margin-top: 50px;
  object-fit: contain;
}

.tag {
  width: 200px;
}

.modal {
  background-color: rgba(2, 2, 26, 0.82);
}

.modal-bg {
  background-color: #191919;
  border-radius: 0;
}

.subs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.subs .modal-body {
  max-width: 900px;
}

.search .form-control {
  border-radius: 40px !important;
  border: 2px solid #fee0e0;
}

.search-box {
  position: relative;
}

.search-box .form-control:active {
  border: 0;
}

.search-box .form-control {
  border-radius: 40px;
  padding: 10px 25px;
}

.search-box .btn {
  position: absolute;
  right: 5px;
  top: 4px;
}

.search-box input {
  flex: 1;
  border: none;
  outline: none;
}

.search-box button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}

.custom-btn {
  border: 0;
  border-radius: 2px;
  background-color: #4169e1;
  color: #fff;
  padding: 8px 30px;
}

footer .form-control {
  border-radius: 2px;
  padding: 7px;
  width: 30%;
}

.mobile {
  width: 250px;
  height: 50vh;
  border-radius: 30px;
  border: 4px solid #000;
  box-shadow: 10px 10px 10px 10px #999;
}

.company-logo {
  width: 40px;
  height: 40px;
  display: block;
  border-radius: 100%;
  padding: 2px;
  border: 1px solid #e7d8d8;
  object-fit: contain;
  background-color: #f9f9f9;
}

.job-count {
  font-size: 1rem;
  font-style: italic;
  color: #0c9;
}

.green {
  background-color: #0c9;
  border-radius: 4px;
  color: #fff !important;
}

.italic {
  font-style: italic;
}

.list {
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.dark {
  background-color: #1b1b2a;
}

.logo {
  height: 35px;
}

.box {
  width: 45%;
  margin: 10px;
}

.sm {
  font-size: 10px;
  margin: 0;
}

.ht {
  min-height: 50vh;
  margin: 50px auto;
}

.mt {
  margin-top: 70px;
}

.link {
  cursor: pointer;
}

.search-box {
  width: 40%;
  display: flex;
  justify-content: center;
  margin: 20px auto;
}

.open {
  cursor: pointer;
  color: #000;
}

.tabs > button {
  border: 0;
  background-color: #f8f8f8;
  padding: 10px 30px;
}

.tabs > .active {
  background-color: #4169e1;
  color: #fff;
}

.nav-menu a {
  color: #fff !important;
  padding: 10px;
  border-bottom: 1px solid transparent;
}

.web-menu a:hover,
.drawer a:hover,
.hide-menu a:hover {
  border-bottom: 1px solid #c77373;
}

.iframe {
  width: 320px;
  height: 250px;
}

.social-logo {
  width: 30px;
  height: 30px;
  margin-right: 20px;
  border-radius: 50%;
  object-fit: contain;
}

/* Tag CSS */

li {
  list-style: none;
}

a {
  text-decoration: none;
}

th {
  vertical-align: bottom;
}
.text-info {
  color: #2e4792 !important;
}

/* Menu CSS Navigation */

.menu-toggle {
  display: none;
}

.drawer {
  position: fixed;
  top: 0;
  padding-top: 50px;
  right: -250px;
  width: 250px;
  height: 100%;
  z-index: 1000000 !important;
  background-color: #343a40;
  transition: right 0.3s ease;
}

.drawer.open {
  right: 0;
}

.menu-toggle {
  position: fixed;
  top: 25px;
  right: 25px;
  cursor: pointer;
  color: #fff;
}

/* Details page css */
.detailspage h1,
.detailspage h2,
.detailspage h3,
.detailspage h4,
.detailspage h5,
.detailspage h6 {
  font-size: 1.5rem;
  color: #2e4792 !important;
}

.detailspage ul {
  margin: 0;
  padding: 0;
  margin-bottom: 40px !important;
}

.detailspage p strong {
  font-weight: bold;
  color: #2e4792 !important;
}

.detailspage li {
  list-style: disc;
}

/* Media Responsiveness */

@media screen and (max-width: 1100px) {
  .blogText {
    padding-left: 5px;
    margin-top: 15px;
  }
  .form-control,
  .form-select {
    width: 100%;
    margin: 10px;
  }
  .menu-toggle {
    display: block;
    z-index: 1000000 !important;
  }
  .hide-menu {
    display: none !important;
  }

  .blogImage,
  .blogSmall,
  .blog-list .col-md-4 {
    width: 100%;
  }

  .search-box,
  .iframe {
    width: 85%;
  }

  .search-box .btn {
    top: 13px;
    right: 10px;
  }

  footer .form-control,
  .custom-btn,
  .box {
    width: 100%;
  }

  .detailspage {
    padding: 25px !important;
    margin: 0 !important;
    margin-bottom: 30px !important;
  }
}
